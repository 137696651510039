var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{},[_c('PageTitle',{attrs:{"items":{
      title: 'コラム',
      subTitle: '一覧',
      tabs: [
        {
          title: '公開中',
          count: _vm.getMediaCounts ? _vm.getMediaCounts.total_opened : 0,
          action: function () {
            _vm.tabAction('N')
          }
        },
        {
          title: '下書き',
          count: _vm.getMediaCounts ? _vm.getMediaCounts.total_drafted : 0,
          action: function () {
            _vm.tabAction('Y')
          }
        }
      ],
      buttons: [
        {
          title: '詳細条件検索',
          class: 'bg-white',
          others: {
            outlined: true
          },
          action: function () {
            _vm.searchBox = !_vm.searchBox
          }
        },
        {
          title: '新規作成',
          icon: 'mdi-plus-circle',
          action: function () {
            _vm.$router.push({ name: 'CreateMediaPost' })
          }
        }
      ]
    }}}),_c('v-fade-transition',[(_vm.searchBox)?_c('SearchBox',_vm._b({staticClass:"mb-5",attrs:{"searchPlacholder":"タイトル"},on:{"search-table":function($event){return _vm.searchTable($event)},"toggleSearch":_vm.toggleSearch}},'SearchBox',{ toggleSearch: _vm.searchBox },false)):_vm._e()],1),_c('DataTable',{ref:"pagination",attrs:{"items":_vm.loading ? [] : _vm.getAllMedia,"headers":_vm.headers,"total-records":_vm.getMediaPagination ? _vm.getMediaPagination.records_total : 0,"number-of-pages":_vm.getMediaPagination ? _vm.getMediaPagination.total_pages : 0},on:{"update:options":_vm.updateTable,"click:row":function($event){return _vm.$router.push({ name: 'EditMediaPost', params: { id: $event.id } })}},scopedSlots:_vm._u([{key:"item.title",fn:function(ref){
    var item = ref.item;
return [_c('v-sheet',{staticClass:"text-truncate",attrs:{"color":"transparent","max-width":"450px"}},[_vm._v(_vm._s(item.title)+" ")])]}},{key:"item.public_date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.public_date && item.public_date != '' ? item.public_date : '---- / - / -','YYYY/MM/DD'))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }